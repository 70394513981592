import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import en from './en.json'
import fr from './fr.json'
import it from './it.json'
import de from './de.json'

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    // Define the fallback language
    fallbackLng: "en",
    // Define supported languages
    supportedLngs: ["en", "fr", "it", "de"], // Add more languages if needed
    // Add language resources
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      },
      it: {
        translation: it
      },
      de: {
        translation: de
      }
      // Add more languages and translations as needed
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;