import { Toolbar, Typography, IconButton, Menu, MenuItem, Button, Box, Theme } from '@mui/material';
import { Language as LanguageIcon, DarkModeTwoTone as ThemeIcon } from '@mui/icons-material';
import React, { useContext } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import SettingsStore from 'src/stores/SettingsStore';
import { themeOne, themeTwo, themeThree } from '../../styles/Theme';
import { toJS } from 'mobx';
import AuthStore from 'src/stores/AuthStore';
import OrgStore from "../../stores/OrgStore";

function TopNavBar() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token, tokenData, login, logOut }: IAuthContext = useContext(AuthContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeLanguage = (languageChoice: string, popupState: any) => {
    i18n.changeLanguage(languageChoice);
    popupState.close();
  };

  const isThemeActive = (theme: Theme) => {
    return SettingsStore.theme.palette.primary.main === theme.palette.primary.main;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleThemeChange = (theme: Theme, popupState: any) => {
    SettingsStore.updateTheme(theme);
    popupState.close();
  }


  useNavigate();

  return (
    <Toolbar className='toolbar-background' color="primary">
      <Box
        component="img"
        sx={{
          height: 'auto',
          width: 'auto'
        }}
        src={process.env.PUBLIC_URL + '/hygiaso_logo.png'}
        alt="Logo"
        onClick={() => navigate('/home')} 
      />

      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        {/** Spacer/Place for navigation routes */}
      </Box>

      <PopupState variant="popover" popupId="theme-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton color="primary" {...bindTrigger(popupState)}>
              <ThemeIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleThemeChange(toJS(themeOne), popupState)}>
                <Typography color="primary" sx={{ fontWeight: isThemeActive(themeOne) ? "bold" : "normal" }}>{
                  t("settings.theme.one")
                }</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleThemeChange(toJS(themeTwo), popupState)}>
                <Typography color="primary" sx={{ fontWeight: isThemeActive(themeTwo) ? "bold" : "normal" }}>{
                  t("settings.theme.two")
                }</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleThemeChange(toJS(themeThree), popupState)}>
                <Typography color="primary" sx={{ fontWeight: isThemeActive(themeThree) ? "bold" : "normal" }}>{
                  t("settings.theme.three")
                }</Typography>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <PopupState variant="popover" popupId="language-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton  color="primary" {...bindTrigger(popupState)}>
              <LanguageIcon />
              <Typography color="primary" sx={{ px:2 }}>{i18n.language.toUpperCase()}</Typography>
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => changeLanguage('en', popupState)}>
                <Typography color="primary">{
                  t('settings.language.english')
                }</Typography>
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('it', popupState)}>
                <Typography color="primary">{
                  t('settings.language.italian')
                }</Typography>
              </MenuItem>
              <MenuItem onClick={() => changeLanguage('de', popupState)}>
                  <Typography color="primary">{
                    t('settings.language.german')
                  }</Typography>
                </MenuItem>
              <MenuItem onClick={() => changeLanguage('fr', popupState)}>
                <Typography color="primary">{
                  t('settings.language.french')
                }</Typography>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    
    {
      
      token

      ?

      <PopupState variant="popover" popupId="account-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button color='primary' variant='outlined' {...bindTrigger(popupState)}>{
                  tokenData ? tokenData['sub'] : "User Name"
            }</Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => {
                OrgStore.reset();
                logOut();
                navigate('/');
              } }>
                <Typography color="primary">{
                  t('toolbar.menu.logout')
                }</Typography>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      
      :

      <Button color="primary" variant='outlined' onClick={() => login()}>{
        t('toolbar.login')
      }</Button>

    }

    </Toolbar>
  );
}

export default TopNavBar;