import { IPageableObject, ISortObject } from "./org.types";

export interface IAuthToken {
    id: string;
    name?: IUserName;

    sub: string;
    aud: string;
    iss: string;
    nbf: string; 
    iat: string;
    exp: string;
    jti: string;
    scope: string[];
    authorities: string[]; //authorities.roles[];
    
    thryveId?: string;
}

export interface IUserName {
    title: string;
    first: string;
    middle: string;
    last: string;
}


export interface IUser {
    id: string;
    idType: string;
}

export interface IUserWithRoles {
    id: string;
    idType: string;
    roles: string[];
}

export interface IPageUser {
    totalPages: number;
    totalElements: number;
    first: boolean;
    last: boolean;
    size: number; 
    content: IUser[];
    number:  number;
}

export interface IPageAdmin {
    totalPages: number;
    totalElements: number;
    first: boolean;
    last: boolean;
    size: number;
    content: string[];
    number:  number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIPageUser(object: any): object is IPageUser {
    return 'totalPages' in object
        && 'totalElements' in object
        && 'first' in object
        && 'last' in object
        && 'size' in object
        && 'content' in object
        && 'number' in object;
}



export default isIPageUser;