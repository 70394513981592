
import React, {useCallback, useContext, useState} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {useTranslation} from "react-i18next";
import OrgStore from "../stores/OrgStore";
import {Alert, IconButton, List, ListItem, TextField} from "@mui/material";
import AuthStore from "../stores/AuthStore";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";

interface RefreshRequestor {
  requestRefreshCallback: (refresh: boolean) => void;
}

const AddUserToCurrentOrganization: React.FC<RefreshRequestor> = ({requestRefreshCallback}) => {
  const [userToAdd, setUserToAdd] = useState<string>('');
  const {token} = useContext(AuthContext);
  const {t} = useTranslation();
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string|undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);

  const handleUserFieldChange = (event) => {
    setUserToAdd(event.target.value);
    setDirty(true);
    setAlert(undefined);
  }

  const handleAdd = useCallback(async () => {
    if (dirty) {
      try {
        await OrgStore.addUser({
          id: userToAdd,
          roles: [],
          idType: ""
        }, token)
        setErrorAlert(false);
        setAlert(t('result.success'));
      } catch (e) {
        setErrorAlert(true);
        setAlert(t('result.error', {errorMessage: (e as Error).message}));
      }
      requestRefreshCallback(true);
      setUserToAdd('');
      setDirty(false);
    }
  }, [dirty, userToAdd, t, requestRefreshCallback])

  return (
    <List>
      { AuthStore.isOrganisationAdmin() && (
        <ListItem>
          <TextField
            value={userToAdd}
            onChange={handleUserFieldChange}
            sx={{ width: 300 }}
            label={t("org.add_user")}
            inputProps={{onKeyDown: e => e.stopPropagation()}}
          >
          </TextField>

          <IconButton disabled={!dirty} onClick={handleAdd} color="primary">
            <AddIcon />
          </IconButton>
        </ListItem>
      )}

      {alert && (
        <ListItem>
          <Alert
            icon={isErrorAlert ? <ErrorIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
            severity={isErrorAlert ? "error" : "success"}>
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  )

}


export default AddUserToCurrentOrganization;
