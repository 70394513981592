import { BrowserRouter as Router } from 'react-router-dom'; 
import AppRouter from './routing/AppRouter';
import { AuthContext, AuthProvider, IAuthContext, TAuthConfig } from 'react-oauth2-code-pkce'
import React, { useContext } from 'react'
import { Box } from '@mui/material';

const loginBaseUrl : string = process.env.REACT_APP_LOGIN_ENDPOINT || "";
const ownBaseUrl : string = process.env.REACT_APP_OWN_ADDRESS || "";

console.log(process.env.REACT_APP_LOGIN_ENDPOINT)

const authConfig: TAuthConfig = {
  clientId: 'rbac_front_end',
  authorizationEndpoint: loginBaseUrl + '/oauth2/v1/authorize',
  tokenEndpoint: loginBaseUrl + '/oauth2/v1/token',
  redirectUri: ownBaseUrl,
  scope: 'openid profile rbac',
  logoutEndpoint: loginBaseUrl + '/oauth2/v1/logout',
  logoutRedirect: ownBaseUrl,
  autoLogin: false,
  storage: 'session',
  storageKeyPrefix: 'hygiaso_',
  refreshWithScope: true
}

console.log(authConfig)

function App() {
  useContext<IAuthContext>(AuthContext)

  return (
    <AuthProvider authConfig={authConfig}>
      <Box component="main" sx={{ height: '100vh', overflow: 'auto', }} >
        <Router>
          <AppRouter />
        </Router>
      </Box>
    </AuthProvider>
  );

}

export default App;