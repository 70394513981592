import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { IAuthToken } from '../models/user.types';
import { TTokenData } from 'react-oauth2-code-pkce/dist/types';
import { useTranslation } from 'react-i18next';

class AuthStore {
    authToken: IAuthToken | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, {
            name: 'AuthStore',
            properties: ['authToken'],
            storage: window.sessionStorage
        });
    }

    clearAllValues() {
        this.authToken = null;
    }

    mapToken(token?: TTokenData, idTokenData?: TTokenData) {
        if (!token) {
            this.authToken = null;
            return;
        }
        this.authToken = {
            id: idTokenData?.name ?? idTokenData?.sub,
            sub: token.sub,
            aud: token.aud,
            iss: token.iss,
            iat: token.iat,
            nbf: token.nbf,
            exp: token.exp,
            jti: token.jti,
            scope: token.scope,
            authorities: token.authorities,
        };
    }

    isOrganisationAdmin() {
        return this.authToken?.authorities.includes('ROLE_ORGANIZATION_ADMIN');
    }

    isAdmin() {
        return this.authToken?.authorities.includes('ROLE_ADMIN');
    }

    isUser(){
        return this.authToken?.authorities.includes('ROLE_OIDC_USER');
    }

    getRole() {
        const { t } = useTranslation();
        const roles: string[] = [];
        if (this.isAdmin())
            roles.push(t('role.admin'));
        if (this.isOrganisationAdmin())
            roles.push(t('role.organisationAdmin'));
        if (roles.length === 0)
            return t('role.user');

        return roles.join(', ');
    }
}

export default new AuthStore();
