import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import isIPageUser, {IPageUser, IUserWithRoles, IPageAdmin} from '../models/user.types';
import isIPageOrg, { IPageOrg, IOrgWithRoles } from 'src/models/org.types';
import {
    addAdminToOrganisation,
    addNewOrganisation,
    addNewUserToCurrentOrganisation, deleteOrganisation,
    deleteUserForCurrentOrganisation, getAdminsForOrganization, getAllOrganisations,
    getAllUsersForCurrentOrganisation,
    getCurrentOrganisation, getOrganisation,
    getUserForCurrentOrganisation, updateOrganisation,
    updateUserRoleForCurrentOrganisation
} from 'src/services/org.service';
import ResourcesStore from "./ResourcesStore";

class OrgStore {
    users: IPageUser | null = null;
    currentOrganisation: IOrgWithRoles | null = null;
    organisations: IPageOrg | null = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, {
            name: 'OrgStore',
            properties: [
                'users',
                'currentOrganisation',
                'organisations'
            ],
            storage: window.sessionStorage
        });
    }

    reset() {
        this.currentOrganisation = null;
        this.users = null;
        this.organisations = null;
    }

    clearAllValues() {
        this.users = null;
        this.currentOrganisation = null;
        this.organisations = null;
    }

    async fetchAllOrganizations(filter: string | undefined, token: string) {
        if (!token) {
            this.currentOrganisation = null;
            return null;
        }
        try {
            const { data } = await getAllOrganisations(filter, token);
            if (data && isIPageOrg(data)) {
                runInAction(() => {
                    this.organisations = data;
                });
            }
        } catch (error) {
            console.error("Failed to fetch users for the current organization: ", error);
            throw new Error("Failed to fetch users for the current organization: " + (error as Error).message);
        }
    }

    async fetchAdminsForOrganization(id: string, token: string) : Promise<string[]> {
        if (!token) {
            this.currentOrganisation = null;
            return [];
        }
        try {
            const { data } = await getAdminsForOrganization(id, token);
            if (data && isIPageAdmin(data)) {
                return toJS(data).content;
            }
            return [];
        } catch (error) {
            console.error("Failed to fetch admins for the organization: ", error);
            throw new Error("Failed to fetch admins for the organization: " + (error as Error).message);
        }
    }

    async fetchUsersForCurrentOrg(filter: string|undefined, token: string) {
        if(!token) {
            this.users = null;
            return null;
        }
        try {
            const { data } = await getAllUsersForCurrentOrganisation(filter, token);
            if (data && isIPageUser(data)) {
                runInAction(() => {
                    this.users = data;
                });
            }
        } catch (error) {
            console.error("Failed to fetch users for the current organization: ", error);
            throw new Error("Failed to fetch users for the current organization: " + (error as Error).message);
        }
    }

    async fetchCurrentOrg(token: string) {
        if (!token) {
            this.currentOrganisation = null;
            return null;
        }
        try {
            const { data } = await getCurrentOrganisation(token);
            if (data && isIOrgWithRoles(data)) {
                runInAction(async () => {
                    this.currentOrganisation = await ResourcesStore.expandRolesForResources(data, token);
                });
            }
        } catch (error) {
            console.error("Failed to fetch current organisation: ", error);
            throw new Error("Failed to fetch current organisation: " + (error as Error).message);
        }
    }

    async getUser(id: string, token: string) {
        if(!token) {
            return null;
        }
        try {
            const { data } = await getUserForCurrentOrganisation(id, token);
            if (data && isIUserWithRoles(data)) {
                return toJS(data) ;
            }
            return null;
        } catch(error) {
            console.error("Failed to fetch user: ", error);
            throw new Error("Failed to fetch user: " + (error as Error).message);
        }
    }

    async getOrganization(id: string, token: string) {
        if(!token) {
            return null;
        }
        try {
            const { data } = await getOrganisation(id, token);
            if (data && isIOrgWithRoles(data)) {
                const orgData = data as IOrgWithRoles;
                return {
                    ...orgData,
                    managedResources: await ResourcesStore.resourcesForRoles(orgData.assignableRoles, token),
                };
            }
            return null;
        } catch(error) {
            console.error("Failed to fetch organization: ", error);
            throw new Error("Failed to fetch organization: " + (error as Error).message);
        }
    }

    async addAdminToOrganisation(orgId: string, newAdmin: string, token: string) {
        if(!token) {
            return null;
        }
        try {
            const { data } = await addAdminToOrganisation(orgId, newAdmin, token);
            return data;
        } catch(error) {
            console.error("Failed to add new admin: ", error);
            throw new Error("Failed to add new admin: " + (error as Error).message);
        }
    }

    async addOrganisation(organisationDetails: IOrgWithRoles, token: string) {
        if(!token) {
            return null;
        }
        try {
            const { data } = await addNewOrganisation(organisationDetails, token);
            return data;
        } catch(error) {
            console.error("Failed to add new organisation: ", error);
            throw new Error("Failed to add new organisation: " + (error as Error).message);
        }
    }


    async updateOrganization(orgId: string, updatedOrg: IOrgWithRoles, token: string){
        console.log("updateOrganization");
        console.log(updatedOrg);
        if(!token){
            return null;
        }
        try {
            const { data } = await updateOrganisation(orgId, updatedOrg, token);
            return data;
        }catch(error){
            console.error("Failed to update organization roles: ", error);
            throw new Error("Failed to update organization roles: " + (error as Error).message);
        }
    }

    async deleteOrganization(orgId: string, token: string) {
        if(!token){
            return null;
        }
        try {
            const { data } = await deleteOrganisation(orgId, token);
            return data;
        } catch(error) {
            console.error("Failed to delete organization: ", error);
            throw new Error("Failed to delete organization: " + (error as Error).message);
        }
    }

    async deleteUser(userId: string, token: string) {
        if(!token){
            return null;
        }
        try {
            const { data } = await deleteUserForCurrentOrganisation(userId, token);
            return data;
        } catch(error) {
            console.error("Failed to delete user: ", error);
            throw new Error("Failed to delete roles: " + (error as Error).message);
        }
    }

    async updateUser(userId: string, updatedUser: IUserWithRoles, token: string){
        console.log("updateUser");
        console.log(updatedUser);
        if(!token){
            return null;
        }
        try {
            const { data } = await updateUserRoleForCurrentOrganisation(userId, updatedUser, token);
            return data;
        }catch(error){
            console.error("Failed to update user roles: ", error);
            throw new Error("Failed to update user roles: " + (error as Error).message);
        }
    }

    async addUser(details: IUserWithRoles, token: string) {
        if(!token){
            return null;
        }
        try {
            await addNewUserToCurrentOrganisation(details, token);
        }catch(error){
            console.error("Failed to add user: ", error);
            throw new Error("Failed to add user: " + (error as Error).message);
        }
    }




}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIUserWithRoles(data: any): data is IUserWithRoles {
    // Implement checks to validate if 'data' has the shape of 'IUserWithRoles'
    return 'roles' in data && Array.isArray(data.roles)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIOrgWithRoles(data: any): data is IOrgWithRoles {
    // Implement checks to validate if 'data' has the shape of 'IUserWithRoles'
    return 'assignableRoles' in data && Array.isArray(data.assignableRoles)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIPageAdmin(object: any): object is IPageAdmin {
    return 'totalPages' in object
      && 'totalElements' in object
      && 'first' in object
      && 'last' in object
      && 'size' in object
      && 'content' in object
      && 'number' in object;
}

export default new OrgStore();
