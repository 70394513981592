import {observer} from "mobx-react";
import React, {useContext, useEffect} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {useTranslation} from "react-i18next";
import AuthStore from "../stores/AuthStore";
import OrgStore from "../stores/OrgStore";
import {toJS} from "mobx";
import {Paper, TextField} from "@mui/material";
import {SimpleTreeView, TreeItem} from "@mui/x-tree-view";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CurrentOrganizationDetails from "../components/CurrentOrganizationDetails";
import UserDetails from "../components/UserDetails";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import AddUserToCurrentOrganization from "../components/AddUserToCurrentOrganisation";
import AddOrganization from "../components/AddOrganization";
import OrganizationDetails from "../components/OrganizationDetails";
import AddAdminToOrganisation from "../components/AddAdminToOrganisation";

const styles = {
  icon: {
    width: 16,
    height: 16,
  },
};

const Home = observer(() => {
  const {token} = useContext(AuthContext);
  const {t} = useTranslation();
  const orgViewAllowed = AuthStore.isAdmin();
  const userViewAllowed = AuthStore.isOrganisationAdmin();
  const [filter, setFilter] = React.useState<string>("");
  const [refreshUsers, setRefreshUsers] = React.useState<boolean>(false);
  const [refreshOrganizations, setRefreshOrganizations] = React.useState<boolean>(false);


  useEffect(() => {
    if (AuthStore.isOrganisationAdmin()) {
      OrgStore.fetchUsersForCurrentOrg(filter, token);
      OrgStore.fetchCurrentOrg(token);
    } else {
      OrgStore.fetchCurrentOrg(token).catch(
        e => console.log(e)
      );
    }
    if (AuthStore.isAdmin()) {
      OrgStore.fetchAllOrganizations(filter, token);
    }
  }, [AuthStore.authToken]);

  useEffect(() => {
    if (AuthStore.isAdmin() && refreshOrganizations) {
      OrgStore.fetchAllOrganizations(filter, token);
      setRefreshOrganizations(false);
    }
  }, [refreshOrganizations, filter]);

  useEffect(() => {
    if (AuthStore.isOrganisationAdmin() && refreshUsers) {
      OrgStore.fetchUsersForCurrentOrg(filter, token);
      setRefreshUsers(false);
    }
  }, [refreshUsers, filter]);

  interface OrganizationDetailsProps {
    orgId: string;
  }
  const AdminListLoader: React.FC<OrganizationDetailsProps> = ({orgId}) => {
    const [admins, setAdmins] = React.useState<string[]>([]);
    useEffect(() => {
      OrgStore.fetchAdminsForOrganization(orgId, token).then(
        admins => setAdmins(admins)
      );
    }, [orgId]);

    return (
      <>
        { admins?.map((admin) => (
          <TreeItem key={orgId + "_" + admin} itemId={"admin_list_" + orgId + "_" + admin} label={admin}/>
        ))}
      </>
      );
  }

  return (
    <Paper sx={{margin: "4vw 10vw", padding: "20px", bgcolor: 'primary.light', borderRadius: '24px'}} elevation={0}>
      <h2 color='primary'>{t('home.welcome', {name: AuthStore.authToken?.id})}</h2>
      <h3 color='primary'>{t('home.role', {role: AuthStore.getRole()})}</h3>
      <TextField
        value={filter}
        onChange={e => {
          setFilter(e.target.value);
          if (e.target.value.length >=3 || e.target.value.length === 0) {
            setRefreshOrganizations(true);
            setRefreshUsers(true);
          }
        }}
        sx={{ width: 300 }}
        label="Search..."
        inputProps={{onKeyDown: e => e.stopPropagation()}}
      >
      </TextField>
      <SimpleTreeView disableSelection>
        <TreeItem
          itemId="currentOrg"
          label={<><ApartmentIcon
            sx={styles.icon}/>&nbsp;&nbsp;{t('org.current_org', {name: OrgStore?.currentOrganisation?.name ?? ""})}</>}
        >
          <TreeItem itemId="currentOrgDetails" label={t("details")}>
            <Paper elevation={0}>
              <CurrentOrganizationDetails/>
            </Paper>
          </TreeItem>
          {userViewAllowed && (
            <>
              <TreeItem itemId="currentOrgAddUser" label={t("org.add_user")}>
                <Paper elevation={0}>
                  <AddUserToCurrentOrganization requestRefreshCallback={setRefreshUsers}/>
                </Paper>
              </TreeItem>
              <TreeItem itemId="currentOrgUsers" label={t("user.all")}>
                {
                  OrgStore.users?.content.map(row =>
                    <TreeItem
                      itemId={row.id}
                      key={row.id}
                      label={<>{row.idType == 'EMAIL' ? <EmailIcon sx={styles.icon}/> :
                        <PhoneIcon sx={styles.icon}/>}&nbsp;&nbsp;{row.id} </>}>
                      <Paper elevation={0}>
                        <UserDetails id={row.id} requestRefreshCallback={setRefreshUsers} />
                      </Paper>
                    </TreeItem>
                  )
                }
              </TreeItem>
            </>
          )}
        </TreeItem>

        {orgViewAllowed && (
          <TreeItem itemId="organisations" label={t("org.all")}>
            <>
              <TreeItem itemId="addOrg" label={t("org.new")}>
                <Paper elevation={0}>
                  <AddOrganization requestRefreshCallback={setRefreshOrganizations} />
                </Paper>
              </TreeItem>
              {
                OrgStore.organisations?.content.map(row =>
                  <TreeItem
                    itemId={row.id}
                    key={row.id}
                    label={<><ApartmentIcon sx={styles.icon}/>&nbsp;&nbsp;{row.name} </>}>
                    <TreeItem
                      itemId={"resources_" + row.id}
                      label={t('org.assigned_resources')}
                      >
                      <Paper elevation={0}>
                        <OrganizationDetails id={row.id} requestRefreshCallback={setRefreshOrganizations} />
                      </Paper>
                    </TreeItem>
                    <TreeItem itemId={"admins_" + row.id} label={t("org.admins")}>
                      <TreeItem itemId={"add_admins_" + row.id} label={t('org.add_admin')}>
                        <Paper elevation={0}>
                          <AddAdminToOrganisation  orgId={row.id}/>
                        </Paper>
                      </TreeItem>
                      <TreeItem itemId={"admin_list_" + row.id} label={t('org.admins')}>
                        <AdminListLoader orgId={row.id}/>
                      </TreeItem>
                    </TreeItem>
                  </TreeItem>
                )
              }
            </>
          </TreeItem>
        )}
      </SimpleTreeView>
    </Paper>
  );
});

export default Home;