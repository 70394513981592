import React from 'react';
import { observer } from 'mobx-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SettingsStore from '../stores/SettingsStore';
import { CssBaseline } from '@mui/material';

const CustomThemeProvider = observer(({ children }: { children: React.ReactNode }) => {
  const theme = createTheme(SettingsStore.theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        {children}
    </ThemeProvider>
  );
});

export default CustomThemeProvider;
