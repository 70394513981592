import React, {useState, useEffect, useContext, useCallback} from 'react';
import {
    Alert,
    Autocomplete,
    Chip,
    IconButton,
    List,
    ListItem,
    Stack,
    TextField, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { AuthContext } from 'react-oauth2-code-pkce';
import OrgStore from 'src/stores/OrgStore';
import { IUserWithRoles } from 'src/models/user.types';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";

interface UserDetailsProps {
    id: string;
    requestRefreshCallback: (refresh: boolean) => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({ id, requestRefreshCallback }) => {
    const [userDetails, setUserDetails] = useState<IUserWithRoles>();
    const [roleToAdd, setRoleToAdd] = useState('');
    const { token } = useContext(AuthContext);
    const { t } = useTranslation();
    const [ assignableRoles, setAssignableRoles ] = useState<string[]>([]);
    const [dirty, setDirty] = useState<boolean>(false);
    const [alert, setAlert] = useState<string|undefined>(undefined);
    const [isErrorAlert, setErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        const fetchDetails = async () => {
            const details  = await OrgStore.getUser(id, token);
            let assignableRoles = OrgStore.currentOrganisation?.assignableRoles ?? [];
            const assignedRoles = details ? details.roles ?? [] : [];

            assignableRoles = assignableRoles.filter(role => !assignedRoles.includes(role));

            setUserDetails({
                id: id,
                roles: details ? details.roles : [], 
                idType: details ? details.idType : 'EMAIL'
            });

            setAssignableRoles(assignableRoles);
        };

        if (id && token) {
            fetchDetails().catch( e => {
                setErrorAlert(true);
                setAlert(t('result.error', {errorMessage: (e as Error).message}));
            });
        }
    }, [id, token]);

    const handleTextFieldChange = (event) => {
        setRoleToAdd(event.target.textContent);
    };

    const handleDelete = useCallback((roleToDelete: string) => {
        // console.log("Delete role: " + roleToDelete);
        if (userDetails) {
            const updatedRoles = userDetails.roles.filter(role => role !== roleToDelete);
            setUserDetails({
                ...userDetails,
                roles: updatedRoles
            });
            const orgAssignableRoles = OrgStore.currentOrganisation?.assignableRoles ?? [];
            if (orgAssignableRoles.includes(roleToDelete))
                assignableRoles.push(roleToDelete);
            setAssignableRoles(assignableRoles);
            setAlert(undefined);
            setDirty(true);
        }
    }, [assignableRoles, userDetails]);

    const handleAdd = useCallback(() => {
        console.log("Add role to user: " + roleToAdd);
        if (roleToAdd == null || roleToAdd == '' || roleToAdd == undefined) {
            return;
        }
        if (userDetails && userDetails.roles && !userDetails.roles.includes(roleToAdd)) {
            setUserDetails({
                ...userDetails,
                roles: [...userDetails.roles, roleToAdd],
            });
            const newAssignableRoles = assignableRoles.filter(role => role !== roleToAdd);
            setAssignableRoles(newAssignableRoles);

        }
        setRoleToAdd('');
        setAlert(undefined);
        setDirty(true);
    }, [assignableRoles, userDetails, roleToAdd]);

    const handleSave = useCallback(async () => {
        if (userDetails != null && id){
            try {
                await OrgStore.updateUser(id, userDetails, token);
                setErrorAlert(false);
                setAlert(t('result.success'));
            } catch (e) {
                setErrorAlert(true);
                setAlert(t('result.error', {errorMessage: (e as Error).message}));
            }
        }
        setDirty(false);
    }, [userDetails, t, id]);

    const handleUserDelete = async () => {
        try {
            await OrgStore.deleteUser(id, token);
            requestRefreshCallback(true);
        } catch (e) {
            setErrorAlert(true);
            setAlert(t('result.error', {errorMessage: (e as Error).message}));
        }
    }
    
    return (
        <List>
            <ListItem>
                <Typography>{t('org.roles') + ":"}</Typography>
            </ListItem>
            <ListItem>
                {
                    userDetails && userDetails.roles.length > 0
                    ?
                            <Stack direction="row" spacing={1}  useFlexGap flexWrap="wrap">
                                {
                                    userDetails.roles.map(role =>
                                            <Chip
                                                color={role === 'ROLE_ORGANIZATION_ADMIN' ? 'error' : "primary"}
                                                variant="outlined"
                                                key={role}
                                                label={role}
                                                onDelete={() => handleDelete(role)}
                                            />
                                    )
                                }
                            </Stack>
                    :

                    t('user.not_found')
                }
            </ListItem>
            { (assignableRoles && assignableRoles.length > 0) &&
                  <ListItem>
                    <Autocomplete
                      disablePortal
                      id="add_role"
                      options={assignableRoles}
                      sx={{ width: 300 }}
                      value={roleToAdd}
                      onChange={handleTextFieldChange}
                      renderInput={(params) => <TextField {...params} label={t('user.add_role')} />}
                    />
                    <IconButton onClick={handleAdd} color="primary">
                        <AddIcon />
                    </IconButton>
                </ListItem>
            }
            <ListItem>
                <Stack direction="row" spacing={1}>
                    <Button disabled={!dirty} onClick={handleSave} color="primary">{ t('user.save') }</Button>
                    <Button onClick={handleUserDelete} color="error">{ t('user.delete') }</Button>
                </Stack>
            </ListItem>
            {alert && (
              <ListItem>
                  <Alert
                    icon={isErrorAlert ? <ErrorIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
                    severity={isErrorAlert ? "error" : "success"}>
                      {alert}
                  </Alert>
              </ListItem>
            )}
        </List>
    );
};

export default UserDetails;
