import {Alert, IconButton, List, ListItem, TextField} from "@mui/material";
import React, {useCallback, useContext, useState} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {useTranslation} from "react-i18next";
import AuthStore from "../stores/AuthStore";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import OrgStore from "../stores/OrgStore";

interface OrganizationDetailsProps {
  orgId: string;
}
const AddAdminToOrganisation: React.FC<OrganizationDetailsProps> = ({ orgId }) => {
  const {token} = useContext(AuthContext);
  const {t} = useTranslation();
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string|undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [adminToAdd, setAdminToAdd] = useState<string>('');

  const handleAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminToAdd(event.target.value);
    setDirty(true);
    setAlert(undefined)
  };

  const handleSaveAdmin = useCallback(async() => {
    if (adminToAdd && orgId) {
      try {
        await OrgStore.addAdminToOrganisation(orgId, adminToAdd, token);
        setErrorAlert(false);
        setAlert(t('result.success'));
      } catch (e) {
        setErrorAlert(true);
        setAlert(t('result.error', {errorMessage: (e as Error).message}));
      }
    }
    setDirty(false);
    setAdminToAdd('');
  }, [adminToAdd, orgId, t])


  return (
    <List>
      { AuthStore.isOrganisationAdmin() && (
        <ListItem>
          <TextField
            value={adminToAdd}
            onChange={handleAdminChange}
            sx={{ width: 300 }}
            label={t("org.add_admin")}
            inputProps={{onKeyDown: e => e.stopPropagation()}}
          >
          </TextField>

          <IconButton disabled={!dirty} onClick={handleSaveAdmin} color="primary">
            <AddIcon />
          </IconButton>
        </ListItem>
      )}

      {alert && (
        <ListItem>
          <Alert
            icon={isErrorAlert ? <ErrorIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
            severity={isErrorAlert ? "error" : "success"}>
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  );
}

export default AddAdminToOrganisation;