import React, {useCallback, useContext, useEffect, useState} from "react";
import {AuthContext} from "react-oauth2-code-pkce";
import {useTranslation} from "react-i18next";
import {IOrgWithResources, IOrgWithRoles} from "../models/org.types";
import OrgStore from "../stores/OrgStore";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import ResourceStore from "../stores/ResourcesStore";

interface OrganizationDetailsProps {
  id: string;
  requestRefreshCallback: (refresh: boolean) => void;
}
const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({ id, requestRefreshCallback }) => {
  const [organizationDetails, setOrganizationDetails] = useState<IOrgWithResources | null>();
  const [ availableResources, setAvailableResources] = useState<string[]>([]);
  const {token} = useContext(AuthContext);
  const {t} = useTranslation();
  const [dirty, setDirty] = useState<boolean>(false);
  const [alert, setAlert] = useState<string|undefined>(undefined);
  const [isErrorAlert, setErrorAlert] = useState<boolean>(false);
  const [resourceToAdd, setResourceToAdd] = useState<string>("");

  useEffect(() => {
    const fetchDetails = async () => {
      const details  = await OrgStore.getOrganization(id, token);
      setOrganizationDetails(details);
      let allAvailableResources = await ResourceStore.getAvailableResourceNames();
      const assignedResources = details ? details.managedResources ?? [] : []

      allAvailableResources = allAvailableResources.filter(res => !assignedResources.includes(res));
      setAvailableResources(allAvailableResources);
      console.log(details)
      console.log(allAvailableResources)
    };

    if (id && token) {
      fetchDetails().catch( e => {
        setErrorAlert(true);
        setAlert(t('result.error', {errorMessage: (e as Error).message}));
      });
    }
  }, [id, token]);

  const handleResourceFieldChange = (event) => {
    setResourceToAdd(event.target.textContent);
  };


  const handleResourceDelete = useCallback((resourceToDelete: string) => {
    if (organizationDetails) {
      const updatedResource = organizationDetails.managedResources.filter(res => res !== resourceToDelete);
      setOrganizationDetails({
        ...organizationDetails,
        managedResources: updatedResource
      });

      setAvailableResources([...availableResources, resourceToDelete]);
      setAlert(undefined);
      setDirty(true);
    }
  }, [organizationDetails, availableResources]);

  const handleResourceAdd = useCallback(() => {
    if (resourceToAdd == null) {
      return;
    }
    const newResourceToAdd = resourceToAdd.trim()
    if (!newResourceToAdd.trim() || organizationDetails?.managedResources.includes(resourceToAdd)){
      return;
    }
    if (organizationDetails) {
      const updatedResources = availableResources.filter(res => res !== newResourceToAdd);
      setAvailableResources(updatedResources);
      setOrganizationDetails({
        ...organizationDetails,
        managedResources: [...organizationDetails.managedResources, newResourceToAdd],
      });
    }

    setDirty(true);
    setAlert(undefined)
    setResourceToAdd('');
  }, [resourceToAdd, availableResources, organizationDetails]);

  const handleSave = useCallback(async() => {
    if (organizationDetails != null){
      try {
        const roles: string[] = []

        for (const res of organizationDetails.managedResources)
          roles.push(await ResourceStore.resourceForName(res));

        const newSaveDetails: IOrgWithRoles = {
          name: organizationDetails.name,
          id: organizationDetails.id,
          assignableRoles: roles
        }

        await OrgStore.updateOrganization(id, newSaveDetails, token);
        setErrorAlert(false);
        setAlert(t('result.success'));
      } catch (e) {
        setErrorAlert(true);
        setAlert(t('result.error', {errorMessage: (e as Error).message}));
      }
    }
    setDirty(false);
  }, [organizationDetails, t, id]);

  const handleOrgDelete = async () => {
    try {
      await OrgStore.deleteOrganization(id, token);
      requestRefreshCallback(true);
    } catch (e) {
      setErrorAlert(true);
      setAlert(t('result.error', {errorMessage: (e as Error).message}));
    }
  }



  return (
  <>
    <List>
      <ListItem>
        <Typography>{t('org.assigned_resources') + ":"}</Typography>
      </ListItem>
      <ListItem>
        {
          organizationDetails && organizationDetails.managedResources.length > 0
          ?
            <Stack direction="row" spacing={1}  useFlexGap flexWrap="wrap">
              {
                organizationDetails?.managedResources.map(resource =>
                  <Chip
                    color={"primary"}
                    variant="outlined"
                    key={resource}
                    label={resource}
                    onDelete={() => handleResourceDelete(resource)}
                  />
                )
              }
            </Stack>
            :
            t('org.not_found')
        }
      </ListItem>

      { (availableResources && availableResources.length > 0) &&
        <ListItem>
          <Autocomplete
              disablePortal
              id="add_resource"
              options={availableResources}
              sx={{ width: 300 }}
              value={resourceToAdd}
              onChange={handleResourceFieldChange}
              renderInput={(params) => <TextField {...params} label={t('org.add_resource')} />}
          />
          <IconButton onClick={handleResourceAdd} color="primary">
              <AddIcon />
          </IconButton>
        </ListItem>
      }

      <ListItem>
        <Stack direction="row" spacing={1}>
          <Button disabled={!dirty} onClick={handleSave} color="primary">{ t('org.save') }</Button>
          <Button onClick={handleOrgDelete} color="error">{ t('org.delete') }</Button>
        </Stack>
      </ListItem>
      {alert && (
        <ListItem>
          <Alert
            icon={isErrorAlert ? <ErrorIcon fontSize="inherit" /> : <CheckIcon fontSize="inherit" />}
            severity={isErrorAlert ? "error" : "success"}>
            {alert}
          </Alert>
        </ListItem>
      )}
    </List>
  </>
  );
}

export default OrganizationDetails;